<template>
  <div class="card mt-2">
    <div class="card-body">
      <h5 class="card-title">{{ quarterName(form.company, form.timeline) }} Performance Evaluation - {{ form.evaluatee_bu }}</h5>
      <div class="row">
        <div class="col-3">
          <div>Status: <b :class="statusColor(form.status)">{{ form.status }}</b></div>
          <div>Project: <b>{{ form.project }}</b></div>
          <div v-if="probationEndDate">Probation End Date: <b>{{ probationEndDate }}</b></div>
        </div>
        <div class="col-5">
          <div class="row">
            <div class="col-5">BU Director:</div>
            <div class="col-7"><b>{{ form.approver_name }}</b></div>
          </div>
          <div class="row">
            <div class="col-5">Performance Manager:</div>
            <div class="col-7"><b>{{ form.evaluator_name }}</b></div>
          </div>
          <div class="row">
            <div class="col-5">Employee:</div>
            <div class="col-7"><b>{{ form.evaluatee_name }}</b></div>
          </div>
        </div>
        <div class="col-4">
          <div v-if="linkText !== ''">
            <router-link :to="formItemLink">{{ linkText }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { ACK_TIMELINES } from '@/config/quarter';
import { quarterName } from '@/utils/quarter';
import { statusColor } from '@/utils/ui';

export default {
  name: 'NameCard',
  props: ['form'],
  computed: {
    formItemLink() {
      return '/forms/' + this.form.id;
    },
    probationEndDate() {
      if (this.form.probation_end_date) {
        return moment(this.form.probation_end_date).format("D MMM YYYY");
      } else {
        return '';
      }
    },
    linkText() {
      const myEmail = this.$store.getters.email;
      const isEvaluatee = this.form.evaluatee_email === myEmail;
      const isEvaluator = this.form.evaluator_email === myEmail;
      const isApprover = this.form.approver_email === myEmail;
      const daysFromEnd = moment(this.form.probation_end_date).diff(moment().startOf('day'), 'days');
      if (!this.form.template_name) {
        return '';
      } else if (isEvaluator && this.form.status === 'Pending') {
        return 'To Submit';
      } else if (isApprover && this.form.status === 'Submitted' && this.form.timeline === 'Probation') {
        return 'To Approve';
      } else if (isEvaluator || (isApprover && this.form.status !== 'Pending')) {
        return 'View';
      } else if (isEvaluatee && this.form.status === 'Approved' && ACK_TIMELINES.includes(this.form.timeline)) {
        return 'To Acknowledge';
      } else if (isEvaluatee && this.form.status === 'Approved' && this.form.timeline === 'Probation' && (
          (this.form.approved_decision === 'Fast Pass') ||
          (this.form.approved_decision === 'Non-Confirmation') ||
          (this.form.approved_decision === 'Pass' && daysFromEnd <= 4) ||
          (this.form.approved_decision === 'Extension' && daysFromEnd <= 4)
        )) {
        return 'To Acknowledge';
      } else if (isEvaluatee && this.form.status === 'Completed') {
        return 'View';
      } else {
        return '';
      }
    }
  },
  methods: {
    quarterName,
    statusColor,
  }
}
</script>