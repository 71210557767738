<template>
  <div class="card mt-2">
    <div class="card-body">
      <h5 class="card-title">{{ groupName }}</h5>
      <div class="row">
        <div class="col-3">
          <div>Status: <b :class="statusColor(groupStatus)">{{ groupStatus }}</b></div>
          <div>The number of forms: {{ group.count }}</div>
        </div>
        <div class="col-5">
          <div>Level: <b>{{ group.level }}</b></div>
          <div>BU: <b>{{ group.bu }}</b></div>
          <div>Template: <b>{{ group.template_name }}</b></div>
        </div>
        <div class="col-4">
          <router-link :to="groupLink">{{ groupLinkText }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { quarterName } from '@/utils/quarter';
import { statusColor } from '@/utils/ui';

export default {
  name: 'FormGroupCard',
  props: ['group'],
  computed: {
    groupName() {
      const quarter = quarterName(this.group.company, this.group.timeline);
      return `${quarter} Performance Evaluation - ${this.group.level} - ${this.group.bu}`;
    },
    groupStatus() {
      for (const status of this.group.statusList) {
        if (status !== 'Approved' && status !== 'Completed') {
          return 'Pending';
        }
      }
      return 'Approved';
    },
    groupLink() {
      return `/approvals?timeline=${this.group.timeline}` +
        `&bu=${encodeURIComponent(this.group.bu)}` +
        `&level=${encodeURIComponent(this.group.level)}` +
        `&template=${encodeURIComponent(this.group.template_name)}`;
    },
    groupLinkText() {
      if (this.groupStatus === 'Approved') {
        return 'View';
      } else {
        return 'To Approve';
      }
    }
  },
  methods: {
    quarterName,
    statusColor,
  }
}
</script>
