<template>
  <div class="row mt-5">
    <div class="col-1 offset-2 mt-2 text-end">
      <h5>Timeline:</h5>
    </div>
    <div class="col-2">
      <select class="form-select" v-model="timeline">
        <option value="Probation">Probation</option>
        <option v-for="timeline in FORM_TIMELINES" :key="timeline" :value="timeline">
          {{ quarterName(company, timeline) }}
        </option>
      </select>
    </div>
    <div class="col-1 mt-2 text-end">
      <h5>Status:</h5>
    </div>
    <div class="col-2">
      <select class="form-select" v-model="formStatus">
        <option value="Disabled">Disabled</option>
        <option value="Pending">Pending</option>
        <option value="Submitted">Submitted</option>
        <option value="Calibrated">Calibrated</option>
        <option value="Approved">Approved</option>
        <option value="Completed">Completed</option>
      </select>
    </div>
    <div class="col-4">
      <a class="btn btn-primary me-2" @click="goToNewForm">Add a new Form</a>
      <a class="btn btn-primary" @click="downloadSummary">
        Download {{ quarterName(company, timeline) }}
      </a>
    </div>
  </div>
  <p class="mt-5" v-if="searchDone">Total number of forms: {{ forms.length }}</p>
  <table class="table" v-if="searchDone && forms.length > 0">
    <thead>
      <tr>
        <th scope="col">Company</th>
        <th scope="col">Director Name</th>
        <th scope="col">Director Email</th>
        <th scope="col">PM Name</th>
        <th scope="col">PM Email</th>
        <th scope="col">Employee Name</th>
        <th scope="col">Employee Email</th>
        <th scope="col">Project</th>
        <th scope="col">Template</th>
        <th v-if="isProbation" scope="col">Start Date</th>
        <th v-if="isProbation" scope="col">Day 85th</th>
        <th v-if="isProbation" scope="col">End Date</th>
        <th v-if="isProbation" scope="col">Decision</th>
        <th scope="col">Status</th>
        <th scope="col"></th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="form in forms" :key="form.id" :class="{ 'text-muted': form.status === 'Disabled' }">
        <td>{{ form.company }}</td>
        <td :title="form.approver_name">{{ form.approver_name?.split(" ")[0] }}</td>
        <td>{{ form.approver_email }}</td>
        <td :title="form.evaluator_name + ', ' + form.evaluator_position">
          {{ form.evaluator_name?.split(" ")[0] }}
        </td>
        <td>{{ form.evaluator_email }}</td>
        <td :title="form.evaluatee_name + ', ' + form.evaluatee_position">
          {{ form.evaluatee_name?.split(" ")[0] }}
        </td>
        <td>{{ form.evaluatee_email }}</td>
        <td>{{ form.project?.substring(0, 30) }}</td>
        <td>{{ form.template_name?.replace('_Performance Evaluation', '').substring(0, 30) }}</td>
        <td v-if="isProbation">{{ dateFormat(form.probation_start_date) }}</td>
        <td v-if="isProbation">{{ criticalDate(form.probation_start_date) }}</td>
        <td v-if="isProbation">{{ dateFormat(form.probation_end_date) }}</td>
        <td v-if="isProbation">{{ decision(form) }}</td>
        <td :class="statusColor(form.status)">{{ form.status }}</td>
        <td><router-link v-if="form.template_name" :to="'/forms/' + form.id">View</router-link></td>
        <td><router-link :to="'/forms/' + form.id + '/edit'">Edit</router-link></td>
      </tr>
    </tbody>
  </table>
</template>
  
<script>
import axios from 'axios';
import moment from 'moment';
import { BACKEND } from '@/api';
import { FORM_TIMELINES } from '@/config/quarter';
import { companyName, quarterName } from '@/utils/quarter';
import { statusColor } from '@/utils/ui';

const DATE_FORMAT = "D MMM YYYY";
const CRITICAL_PERIOD = 84;

export default {
  name: 'AdminFormList',
  data() {
    return {
      FORM_TIMELINES: Object.freeze(FORM_TIMELINES),
      forms: [],
      company: '',
      timeline: 'Probation',
      formStatus: 'Pending',
      searchDone: false
    };
  },
  computed: {
    isProbation() {
      return this.timeline === 'Probation';
    }
  },
  watch: {
    timeline() {
      this.setQueryParams();
      this.refreshFormList();
    },
    formStatus() {
      this.setQueryParams();
      this.refreshFormList();
    }
  },
  methods: {
    quarterName,
    statusColor,
    dateFormat(dateStr) {
      return moment(dateStr).format(DATE_FORMAT);
    },
    criticalDate(dateStr) {
      return moment(dateStr).add(CRITICAL_PERIOD, "days").format(DATE_FORMAT);
    },
    decision(form) {
      if ('approved_decision' in form) {
        return form.approved_decision;
      } else if ('submitted_decision' in form) {
        return form.submitted_decision;
      } else {
        return '';
      }
    },
    setQueryParams() {
      this.$router.push({ path: this.$route.path, query: {
        timeline: this.timeline, status: this.formStatus
      }});
    },
    goToNewForm() {
      this.$router.push('/forms/new');
    },
    downloadSummary() {
      let endpoint = `${BACKEND}/forms/admin/download?timeline=${this.timeline}`;
      const timeline = quarterName(this.company, this.timeline).replace("/", "");
      const csvFilename = `Summary_${timeline}_${moment().format("YYYY-MM-DD_HHmmss")}.csv`;
      axios.get(endpoint, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = csvFilename;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    refreshFormList() {
      this.searchDone = false;
      let endpoint = `${BACKEND}/forms/all?timeline=${this.timeline}&status=${this.formStatus}`;
      axios.get(endpoint, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          response.data.sort((a, b) => a.evaluatee_name?.localeCompare(b.evaluatee_name));
          this.forms = response.data;
          if (this.timeline === 'Probation') {
            this.forms.sort((a, b) => a.probation_start_date?.localeCompare(b.probation_start_date));
          }
          this.searchDone = true;

          if (!this.company) {
            this.company = companyName(this.forms);
          }
        }
      }).catch(error => {
        console.log(error.response);
        this.$store.commit('logout');
      });
    }
  },
  created() {
    if (this.$route.query.timeline) {
      this.timeline = this.$route.query.timeline;
    }
    if (this.$route.query.status) {
      this.formStatus = this.$route.query.status;
    }
    this.refreshFormList();
  }
}
</script>

<style scoped>
table.table {
  font-size: 14px;
}
</style>
