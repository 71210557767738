const BLUEBIK_INDIA = 'Bluebik TC';
const BLUEBIK_DEFAULT = 'Default';
const EMPTY = '';

export function companyName(formList) {
  if (formList.length === 0) {
    return EMPTY;
  } else if (formList.every(form => form.company === BLUEBIK_INDIA)) {
    return BLUEBIK_INDIA;
  } else {
    return BLUEBIK_DEFAULT;
  }
}

export function quarterName(company, timeline) {
  if (!timeline) {
    return '';
  }
  const [qStr, fyStr] = timeline.split('/');
  const fy = parseInt(fyStr);
  const q = parseInt(qStr[1]);
  if (isNaN(fy) || isNaN(q)) {
    return timeline;
  }
  if (company === BLUEBIK_INDIA) {
    const newQ = q === 1 ? 4 : q - 1;
    const newFy1 = q === 1 ? fy - 1: fy;
    const newFy2 = q === 1 ? fy: fy + 1;
    return `Q${newQ}/${newFy1}-${newFy2}`;
  } else {
    return `Q${q}/${fy}`;
  }
}