<template>
  <h2 class="mt-5">{{ calibration.name }}</h2>
  <h5>Status: <b :class="statusColor(calibration.status)">{{ calibration.status }}</b></h5>
  <div class="row mt-5">
    <div class="col-2">
      <input type="text" class="form-control" v-model="newFormId" placeholder="New Form ID">
    </div>
    <div class="col-2">
      <a class="btn btn-primary" :class="{disabled: isNewFormIdEmpty}" @click="addForm">Add</a>
    </div>
  </div>
  <table class="table mt-3">
    <thead>
      <tr>
        <th scope="col">Form ID</th>
        <th scope="col">PM Name</th>
        <th scope="col">Employee Name</th>
        <th scope="col">Project</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="form in calibration.forms" :key="form.id">
        <td>{{ form.id }}</td>
        <td>{{ form.evaluator_name }}</td>
        <td>{{ form.evaluatee_name }}</td>
        <td>{{ form.project }}</td>
        <td><a href="#" @click="removeForm(form.id)">Remove</a></td>
      </tr>
    </tbody>
  </table>
  <div class="mt-5"></div>
</template>

<script>
import axios from 'axios';
import { BACKEND } from '@/api';
import { statusColor } from '@/utils/ui';

export default {
  name: 'CalibrationItemEdit',
  data() {
    return {
      calibration: {
        forms: []
      },
      newFormId: '',
    };
  },
  computed: {
    calibrationName() {
      return encodeURIComponent(this.$route.params.name);
    },
    isNewFormIdEmpty() {
      return this.newFormId.trim() === '';
    }
  },
  methods: {
    statusColor,
    removeForm(formId) {
      if (!confirm(`Do you want remove ${formId} from calibration?`)) { return; }
      axios.patch(`${BACKEND}/calibration/${this.calibrationName}`, {
        action_type: 'remove',
        form_id: formId,
      }, {
        headers: { 'Authorization': this.$store.getters.idToken }
      }).then((response) => {
        if (response.status === 200) {
          this.load();
        }
      }).catch((error) => {
        alert(error.response.data.message);
      });
    },
    addForm() {
      axios.patch(`${BACKEND}/calibration/${this.calibrationName}`, {
        action_type: 'add',
        form_id: this.newFormId.trim(),
      }, {
        headers: { 'Authorization': this.$store.getters.idToken }
      }).then((response) => {
        if (response.status === 200) {
          this.newFormId = '';
          this.load();
        }
      }).catch((error) => {
        alert(error.response.data.message);
      });
    },
    load() {
      this.calibration = { forms: [] };
      axios.get(`${BACKEND}/calibration/${this.calibrationName}`, {
        headers: { 'Authorization': this.$store.getters.idToken }
      }).then((response) => {
        if (response.status === 200) {
          response.data.forms.sort((a, b) => a.project.localeCompare(b.project));
          response.data.forms.sort((a, b) => a.evaluator_name.localeCompare(b.evaluator_name));
          response.data.forms.sort((a, b) => a.evaluatee_name.localeCompare(b.evaluatee_name));
          this.calibration = response.data;
        }
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$router.push('/calibration');
        } else {
          console.log(error.response);
          this.$store.commit('logout');
        }
      });
    }
  },
  created() {
    this.load();
  }
}
</script>