<template>
  <form @submit.prevent="submitForm" style="margin-top: 120px;">
    <div class="mb-3">
      <label for="inputEmail" class="form-label">Email address</label>
      <input type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" v-model="email">
    </div>
    <div class="mb-3">
      <label for="inputPassword" class="form-label">Password</label>
      <input type="password" class="form-control" id="inputPassword" v-model="password">
    </div>
    <button type="submit" class="btn btn-primary">Log in</button>
  </form>

</template>

<script>
import axios from 'axios';
import { BACKEND } from '@/api';

export default {
  name: 'LoginPanel',
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    setUserGroups() {
      axios.get(`${BACKEND}/users/claim`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          this.$store.commit("setGroups", {
            groups: response.data['cognito:groups']
          });
          localStorage.setItem('groups', response.data['cognito:groups']);
        }
      });
    },
    submitForm() {
      axios.post(`${BACKEND}/users/auth`, {
        "auth_type": "USER_PASSWORD_AUTH",
        "username": this.email,
        "password": this.password
      }).then((response) => {
        if (response.status === 200) {
          this.$store.commit("loginSuccess", {
            email: this.email,
            idToken: response.data.id_token,
            refreshToken: response.data.refresh_token
          });
          localStorage.setItem('email', this.email);
          localStorage.setItem('idToken', response.data.id_token);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          this.setUserGroups();
          this.$router.push('/forms');
        }
      }).catch((error) => {
        alert("Email or password is not correct");
        console.log(error.response);
      });
    }
  }
}
</script>
