<template>
  <div></div>
</template>

<script>
import axios from 'axios';
import { BACKEND } from '@/api';

export default {
  name: 'LandingPage',
  methods: {
    setUserInfo() {
      axios.get(`${BACKEND}/users/claim`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          this.$store.commit("setEmail", {
            email: response.data.email,
          });
          this.$store.commit("setGroups", {
            groups: response.data['cognito:groups']
          });
          localStorage.setItem('email', response.data.email);
          localStorage.setItem('groups', response.data['cognito:groups']);
        }
      });
    }
  },
  created() {
    for (const portion of this.$route.hash.slice(1).split('&')) {
      const tokens = portion.split('=');
      if (tokens.length > 1 && tokens[0] === 'id_token') {
        this.$store.commit("loginSuccess", {
          idToken: tokens[1],
        });
        localStorage.setItem('idToken', tokens[1]);
        this.setUserInfo();
      }
    }
    this.$router.push('/forms');
  }
}
</script>
