<template>
  <div class="row mt-5">
    <div class="col-1 mt-2">
      <h5>Timeline:</h5>
    </div>
    <div class="col-3">
      <select class="form-select" v-model="timeline">
        <option v-for="timeline in FORM_TIMELINES" :key="timeline" :value="timeline">
          {{ timeline }}
        </option>
      </select>
    </div>
  </div>
  <h5 class="mt-5">New Calibration Session</h5>
  <div class="row">
    <div class="col-2">
      <select class="form-select" v-model="newCompany">
        <option value="">Select company</option>
        <option v-for="company in companyList" :key="company" :value="company">{{ company }}</option>
      </select>
    </div>
    <div class="col-6">
      <input type="text" class="form-control" v-model="newSessionName" placeholder="New Session Name">
    </div>
    <div class="col-2">
      <input type="text" class="form-control" v-model="newFormId" placeholder="New Form ID">
    </div>
    <div class="col-2">
      <a class="btn btn-primary" :class="{disabled: isNewSessionEmpty}" @click="addCaliSession">Add</a>
    </div>
  </div>
  <h5 class="mt-5">List of Calibration Sessions</h5>
  <div class="card mt-2" v-for="record in calibrationList" :key="record.name">
    <div class="card-body">
      <h5 class="card-title">{{ record.name }}</h5>
      <div class="row">
        <div class="col-8">
          <div>Status: <b :class="statusColor(record.status)">{{ record.status }}</b></div>
          <div>The number of forms: {{ record.form_ids.length }}</div>
        </div>
        <div class="col-2">
          <router-link :to="`/calibration/${encodeURIComponent(record.name)}`">
            {{ calibrateLinkText(record.status) }}
          </router-link>
        </div>
        <div class="col-2">
          <router-link :to="`/calibration/${encodeURIComponent(record.name)}/Edit`">
            {{ editLinkText(record.status) }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <h5 v-if="isListEmpty" class="mt-5 text-center"><i>No data</i></h5>
  <div class="mt-5"></div>
</template>

<script>
import axios from 'axios';
import { BACKEND } from '@/api';
import { FORM_TIMELINES } from '@/config/quarter';
import { statusColor } from '@/utils/ui';

export default {
  name: 'CalibrationList',
  data() {
    return {
      FORM_TIMELINES: Object.freeze(FORM_TIMELINES),
      timeline: FORM_TIMELINES[0],
      calibrationList: [],
      isListEmpty: false,
      newCompany: '',
      newSessionName: '',
      newFormId: '',
    };
  },
  computed: {
    companyList() {
      return this.$store.getters.groups?.split(',')
        .filter(x => x.startsWith('Calibration_'))
        .map(x => x.split('_')[1]).sort();
    },
    isNewSessionEmpty() {
      return this.newCompany === '' ||
        this.newSessionName.trim() === '' ||
        this.newFormId.trim() === '';
    }
  },
  watch: {
    timeline() {
      this.setQueryParams();
      this.load();
    }
  },
  methods: {
    statusColor,
    calibrateLinkText(status) {
      return status === 'Calibrated' ? 'View' : 'To Calibrate';
    },
    editLinkText(status) {
      return status === 'Calibrated' ? '' : 'Edit';
    },
    setQueryParams() {
      this.$router.push({ path: this.$route.path, query: { timeline: this.timeline }});
    },
    addCaliSession() {
      axios.post(`${BACKEND}/calibration`, {
        company: this.newCompany,
        timeline: this.timeline,
        name: this.newSessionName,
        form_id: this.newFormId,
      }, {
        headers: { 'Authorization': this.$store.getters.idToken }
      }).then((response) => {
        if (response.status === 200) {
          this.newCompany = '';
          this.newSessionName = '';
          this.newFormId = '';
          this.load();
        }
      }).catch((error) => {
        alert(error.response.data.message);
      });
    },
    load() {
      this.calibrationList = [];
      this.isListEmpty = false;

      axios.get(`${BACKEND}/calibration?timeline=${this.timeline}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          response.data.sort((a, b) => a.name.localeCompare(b.name));
          this.calibrationList = response.data;
          this.isListEmpty = this.calibrationList.length === 0;
        }
      }).catch(error => {
        console.log(error.response);
        this.$store.commit('logout');
      });
    }
  },
  created() {
    if (this.$route.query.timeline) {
      this.timeline = this.$route.query.timeline;
    }
    this.load();
  }
}
</script>