<template>
  <h2 class="mt-5">{{ calibration.name }}</h2>
  <h5>Status: <b :class="statusColor(calibration.status)">{{ calibration.status }}</b></h5>
  <div class="mt-5" v-for="(section, sectionIndex) in template.sections" :key="section.title">
    <h5><b>{{ section.title }}</b></h5>
    <div class="table-responsive-lg horizontal-scroll">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="text-end"><i>Performance Manager</i></th>
            <th scope="col" class="text-center px-1" v-for="form in calibration.forms" :key="form.id" :title="form.evaluator_name">
              {{ form.evaluator_name.split(" ")[0] }}
            </th>
          </tr>
          <tr>
            <th scope="col" class="text-end"><i>Employee</i></th>
            <th scope="col" class="text-center px-1" v-for="form in calibration.forms" :key="form.id" :title="form.evaluatee_name">
              {{ form.evaluatee_name.split(" ")[0] }}
            </th>
          </tr>
          <tr>
            <th scope="col" class="text-end"><i>Project</i></th>
            <th scope="col" class="text-center px-1" v-for="form in calibration.forms" :key="form.id">
              {{ form.project }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(measure, measureIndex) in section.measures" :key="measure">
            <td style="min-width: 540px; max-width: 540px;">
              {{ measure.description }}
            </td>
            <td v-for="(form, formIndex) in calibration.forms" :key="form.id" class="text-center" :class="{
              positive: isScorePositive(formIndex, sectionIndex, measureIndex),
              negative: isScoreNegative(formIndex, sectionIndex, measureIndex)
              }">
              <span v-if="isCalibrated">
                {{ validScore(calibratedValue[formIndex].calibrated[sectionIndex].scores[measureIndex]) }}
              </span>
              <select :class="{'changed': isScoreChanged(formIndex, sectionIndex, measureIndex)}"
                v-if="!isCalibrated && 'calibrated' in calibratedValue[formIndex]"
                v-model="calibratedValue[formIndex].calibrated[sectionIndex].scores[measureIndex]">
                <option value="5">5</option>
                <option value="4">4</option>
                <option value="3">3</option>
                <option value="2">2</option>
                <option value="1">1</option>
                <option value="0" v-if="measure !== 'Overall'">n/a</option>
              </select>
            </td>
          </tr>
          <tr v-if="sectionIndex === template.sections.length - 1">
            <td>Average score</td>
            <td v-for="(score, formIndex) in averageScores" :key="calibration.forms[formIndex].id" class="text-center">{{ score }}</td>
          </tr>
          <tr>
            <td class="text-end">Comment</td>
            <td v-for="(form, formIndex) in calibration.forms" :key="form.id" class="text-center"
              :title="getComment(formIndex, sectionIndex, form.evaluatee_name)">
              <i v-if="getComment(formIndex, sectionIndex, form.evaluatee_name)" style="font-size: 12px;">Hover</i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="text-center mt-3 mb-5">
    <button type="button" v-if="!isCalibrated && isCalibratedValueComplete" class="btn btn-primary" @click="confirm">Confirm</button>
    &nbsp;<button type="button" class="btn btn-outline-dark" @click="cancel">Back</button>
  </div>
</template>

<script>
import axios from 'axios';
import { BACKEND } from '@/api';
import { companyName, quarterName } from '@/utils/quarter';
import { statusColor } from '@/utils/ui';

export default {
  name: 'CalibrationItem',
  data() {
    return {
      calibration: {
        forms: []
      },
      calibratedValue: [],
      originalValue: []
    }
  },
  computed: {
    resourceName() {
      return this.$route.name.includes('Calibration') ? 'calibration': 'approvals';
    },
    template() {
      if (this.calibration.forms.length > 0) {
        return this.calibration.templates[this.calibration.forms[0].template_name];
      } else {
        return {};
      }
    },
    isCalibrated() {
      return ['Calibrated', 'Approved'].includes(this.calibration.status);
    },
    isCalibratedValueComplete() {
      for (const calibratedObj of this.calibratedValue) {
        if (!('calibrated' in calibratedObj)) {
          return false;
        }
      }
      return true;
    },
    averageScores() {
      let avgScores = [];
      for (const form of this.calibratedValue) {
        if (!('calibrated' in form)) {
          avgScores.push('');
          continue;
        }
        let scoreTotal = 0;
        let weightTotal = 0;
        for (const [sectionIndex, section] of this.calibration.templates[form.template_name].sections.entries()) {
          for (const [measureIndex, measure] of section.measures.entries()) {
            const score = parseInt(form.calibrated[sectionIndex].scores[measureIndex]);
            if (typeof score !== 'undefined' && score > 0) {
              const weight = parseFloat(measure.weight);
              scoreTotal += weight * score;
              weightTotal += weight;
            }
          }
        }
        if (weightTotal > 0) {
          avgScores.push((scoreTotal / weightTotal).toFixed(2));
        } else {
          avgScores.push('');
        }
      }
      return avgScores;
    }
  },
  methods: {
    statusColor,
    isScoreChanged(formIndex, sectionIndex, measureIndex) {
      if (!('calibrated' in this.calibratedValue[formIndex])) {
        return false;
      }
      const currentScore = parseInt(this.calibratedValue[formIndex].calibrated[sectionIndex].scores[measureIndex]);
      return currentScore !== parseInt(this.originalValue[formIndex][sectionIndex].scores[measureIndex]);
    },
    isScorePositive(formIndex, sectionIndex, measureIndex) {
      if (!('calibrated' in this.calibratedValue[formIndex])) {
        return false;
      }
      const currentScore = parseInt(this.calibratedValue[formIndex].calibrated[sectionIndex].scores[measureIndex]);
      return currentScore === 1 || currentScore === 2;
    },
    isScoreNegative(formIndex, sectionIndex, measureIndex) {
      if (!('calibrated' in this.calibratedValue[formIndex])) {
        return false;
      }
      const currentScore = parseInt(this.calibratedValue[formIndex].calibrated[sectionIndex].scores[measureIndex]);
      return currentScore === 4 || currentScore === 5;
    },
    getComment(formIndex, sectionIndex, name) {
      if (!('calibrated' in this.calibratedValue[formIndex])) {
        return '';
      }
      const comment = this.calibratedValue[formIndex].calibrated[sectionIndex].comment;
      if (comment) {
        return name + ' - ' + comment;
      } else {
        return '';
      }
    },
    validScore(score) {
      return (score > 0) ? score : 'n/a';
    },
    confirm() {
      const isConfirmed = confirm("After you confirm, you can come back to this page, but cannot edit the data. Do you want to continue?");
      if (!isConfirmed) {
        return;
      }
      let postUrl = `${BACKEND}/${this.resourceName}`;
      if ('name' in this.$route.params) {
        postUrl += `/${encodeURIComponent(this.$route.params.name)}`;
      }
      axios.post(postUrl, this.calibratedValue, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          if (this.resourceName == 'approvals') {
            this.$router.push('/forms');
          } else {
            this.$router.push(`/${this.resourceName}`);
          }
        }
      });
    },
    cancel() {
      this.$router.back();
    }
  },
  created() {
    axios.get(`${BACKEND}${this.$router.currentRoute.value.fullPath}`, {
      headers: {
        'Authorization': this.$store.getters.idToken
      }
    }).then((response) => {
      if (response.status === 200) {
        this.calibration = response.data;

        const company = companyName(response.data.forms);
        const quarter = quarterName(company, this.calibration.timeline);
        this.calibration.name = this.calibration.name.replace(this.calibration.timeline, quarter);

        response.data.forms.sort((a, b) => a.project.localeCompare(b.project));
        response.data.forms.sort((a, b) => a.evaluator_name.localeCompare(b.evaluator_name));
        response.data.forms.sort((a, b) => a.evaluatee_name.localeCompare(b.evaluatee_name));
        this.calibratedValue = new Array(response.data.forms.length);
        this.originalValue = new Array(response.data.forms.length);
        for (let i = 0; i < response.data.forms.length; i++) {
          const form = response.data.forms[i];
          this.calibratedValue[i] = {
            form_id: form.id,
            template_name: form.template_name
          };
          if (this.resourceName === 'approvals' && form.calibration === 'Y' && !('calibrated' in form)) {
            continue;
          } else if ('approved' in form) {
            this.calibratedValue[i].calibrated = JSON.parse(JSON.stringify(form.approved));
            this.originalValue[i] = JSON.parse(JSON.stringify(form.approved));
          } else if ('calibrated' in form) {
            this.calibratedValue[i].calibrated = JSON.parse(JSON.stringify(form.calibrated));
            this.originalValue[i] = JSON.parse(JSON.stringify(form.calibrated));
          } else if ('submitted' in form) {
            this.calibratedValue[i].calibrated = JSON.parse(JSON.stringify(form.submitted));
            this.originalValue[i] = JSON.parse(JSON.stringify(form.submitted));
          }
        }
      }
    }).catch(error => {
      console.log(error.response);
      this.$store.commit('logout');
    });
  }
}
</script>

<style>
table.table tbody tr td,
table.table thead tr th {
  border: 1px solid lightgray;
}

div.horizontal-scroll {
  overflow-x: auto;
}

div.horizontal-scroll tr>th:first-child,
div.horizontal-scroll tr>td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  background-clip: padding-box;
  border-left: 0px;
}

select.changed {
  background-color: yellow;
}

td.positive {
  background-color: lightgreen;
}

td.negative {
  background-color: orange;
}
</style>