<template>
  <h2 class="mt-5">{{ quarterName(form.company, form.timeline) }} Performance Evaluation - {{ form.evaluatee_bu }}</h2>
  <h5>Status: <b :class="statusColor(form.status)">{{ form.status }}</b></h5>
  <div>Submit Timestamp: <b>{{ submittedTime }}</b></div>
  <div>Approve Timestamp: <b>{{ approvedTime }}</b></div>
  <div>Acknowledge Timestamp: <b>{{ acknowledgedTime }}</b></div>
  <div class="mt-3">Project: <b>{{ form.project }}</b></div>
  <div v-if="!isProbation">Due Date: <b>{{ dueDate }}</b></div>
  <div v-if="isProbation">Probation Start Date: <b>{{ probationStartDate }}</b></div>
  <div v-if="isProbation">Probation End Date: <b>{{ probationEndDate }}</b></div>
  <div class="row mt-5">
    <div class="col-4"><h5>BU Director</h5></div>
    <div class="col-4"><h5>Performance Manager</h5></div>
    <div class="col-4"><h5>Employee</h5></div>
  </div>
  <div class="row">
    <div class="col-4">Name: <b>{{ form.approver_name }}</b></div>
    <div class="col-4">Name: <b>{{ form.evaluator_name }}</b></div>
    <div class="col-4">Name: <b>{{ form.evaluatee_name }}</b></div>
  </div>
  <div class="row">
    <div class="col-4">Email: <b>{{ form.approver_email }}</b></div>
    <div class="col-4">Email: <b>{{ form.evaluator_email }}</b></div>
    <div class="col-4">Email: <b>{{ form.evaluatee_email }}</b></div>
  </div>
  <div class="row">
    <div class="col-4"></div>
    <div class="col-4">Position: <b>{{ form.evaluator_position }}</b></div>
    <div class="col-4">Position: <b>{{ form.evaluatee_position }}</b></div>
  </div>
  <div class="row mt-5">
    <div class="col-12"><b>Rating Scale</b></div>
    <div class="col-12"><b>5</b>: Did not meet expectations</div>
    <div class="col-12"><b>4</b>: Met certain expectations</div>
    <div class="col-12"><b>3</b>: Met ALL expectations</div>
    <div class="col-12"><b>2</b>: Exceeded expectations compared to peer group</div>
    <div class="col-12"><b>1</b>: Outstanding</div>
    <div class="col-12"><b>n/a</b>: Not applicable</div>
  </div>
  <div class="mt-5" v-for="(section, sectionIndex) in template.sections" :key="section.title"><h5><b>{{ section.title }}</b></h5>
    <div v-for="(measure, measureIndex) in section.measures" :key="measure" class="row" style="min-height: 48px;">
      <div class="col-7">{{ measure.description }}</div>
      <div class="col-1" v-if="template.is_weighted && measure.weight > 0">{{ (measure.weight * 100).toFixed(2) }}%</div>
      <div class="col-1" v-else></div>
      <div class="col-4" v-if="editable">
        <label class="pe-3 me-3" v-for="value in [5, 4, 3, 2, 1]" :key="value">
          <input type="radio" :name="measure.description" :value="value" v-model="submittedValue[sectionIndex]['scores'][measureIndex]">
          {{ value }}
        </label>
        <label v-if="measure.description !== 'Overall'">
          <input type="radio" :name="measure.description" value="0" v-model="submittedValue[sectionIndex]['scores'][measureIndex]">
          n/a
        </label>
      </div>
      <div class="col-4" v-else>
        <span v-if="submittedValue[sectionIndex]['scores'][measureIndex] > 0">{{ submittedValue[sectionIndex]['scores'][measureIndex] }}</span>
        <span v-else-if="submittedValue[sectionIndex]['scores'][measureIndex] == 0">n/a</span>
      </div>
      <hr />
    </div>
    <div class="row" v-if="(isApprover || isEvaluator) && sectionIndex === template.sections.length - 1">
      <div class="col-8">Average Score</div>
      <div class="col-4">{{ averageScore() }}</div>
      <hr />
    </div>
    <div class="row">
      <div class="col-1"><b>comment</b></div>
      <div class="col-11">
        <textarea v-if="editable" style="width: 100%" v-model="submittedValue[sectionIndex]['comment']"></textarea>
        <p v-else>{{ submittedValue[sectionIndex]['comment'] }}</p>
      </div>
    </div>
  </div>
  <div v-if="isProbation" class="mt-5 p-3" style="border-style: solid; border-color: gray;">
    <div class="row">
      <div class="col-8 mt-2"><h5><b>Probation Decision</b></h5></div>
      <div class="col-4" v-if="editable">
        <select class="form-select" v-model="submittedDecision">
          <option v-if="hasFastPass" value="Fast Pass">Fast Pass</option>
          <option value="Pass">Pass</option>
          <option value="Extension">Extension</option>
          <option value="Non-Confirmation">Non-Confirmation</option>
        </select>
      </div>
      <div class="col-4 mt-2" v-else>
        <span><h5>{{ submittedDecision }}</h5></span>
      </div>
    </div>
  </div>
  <div v-if="(isEvaluator || isApprover) && !editable" class="mt-3">
    <i>
      The evaluation form has been submitted and cannot be edited.
      If you need to make any changes, please send an email to talents@bluebik.com.
    </i>
  </div>
  <div class="text-center mt-5 mb-5">
    <button type="button" v-if="submitAvailable" class="btn btn-primary" @click="submit()">Submit</button>
    <button type="button" v-if="approveAvailable" class="btn btn-primary" @click="approve()">Approve</button>
    <button type="button" v-if="acknowledgeAvailable" class="btn btn-primary" @click="acknowledge">Acknowledge</button>
    <button type="button" v-if="submitAvailable" class="btn btn-secondary ms-2" @click="submit(true)">Save draft</button>
    <button type="button" v-if="approveAvailable" class="btn btn-secondary ms-2" @click="approve(true)">Save draft</button>
    <button type="button" class="btn btn-outline-dark ms-2" @click="cancel">Back</button>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { BACKEND } from '@/api';
import { FORM_DUE_DATES } from '@/config/quarter';
import { quarterName } from '@/utils/quarter';
import { statusColor } from '@/utils/ui';

const NO_FAST_PASS_COMP = ["Bluebik Vietnam"];
const DATE_FORMAT = "D MMM YYYY";

export default {
  name: 'FormItem',
  data() {
    return {
      form: {},
      template: {},
      submittedValue: [],
      submittedDecision: undefined,
      isEvaluatee: false,
      isEvaluator: false,
      isApprover: false
    };
  },
  computed: {
    dueDate() {
      return FORM_DUE_DATES[this.form.timeline];
    },
    submitAvailable() {
      return this.isEvaluator && this.form.status === 'Pending';
    },
    approveAvailable() {
      return this.isApprover && this.form.status === 'Submitted' && this.isProbation;
    },
    acknowledgeAvailable() {
      return this.isEvaluatee && this.form.status === 'Approved';
    },
    editable() {
      return this.submitAvailable || this.approveAvailable;
    },
    lastSubmittedValue() {
      return this.submittedValue[this.submittedValue.length - 1];
    },
    hasFastPass() {
      return !NO_FAST_PASS_COMP.includes(this.form.company);
    },
    isProbation() {
      return this.form.timeline === 'Probation';
    },
    probationStartDate() {
      return 'probation_start_date' in this.form ? this.dateString(this.form.probation_start_date) : '';
    },
    probationEndDate() {
      return 'probation_end_date' in this.form ? this.dateString(this.form.probation_end_date) : '';
    },
    submittedTime() {
      return 'submitted_timestamp' in this.form ? this.timestampToDatetime(this.form.submitted_timestamp) : '';
    },
    approvedTime() {
      return 'approved_timestamp' in this.form ? this.timestampToDatetime(this.form.approved_timestamp) : '';
    },
    acknowledgedTime() {
      return 'acknowledged_timestamp' in this.form ? this.timestampToDatetime(this.form.acknowledged_timestamp) : '';
    },
    isSubmittedValueComplete() {
      if (this.isProbation && typeof this.submittedDecision === 'undefined') {
        return false;
      }
      for (const section of this.submittedValue) {
        if (section.scores.includes(undefined) || section.scores.includes(null)) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    quarterName,
    statusColor,
    dateString(_dateString) {
      return moment(_dateString, 'YYYY-MM-DD').format(DATE_FORMAT);
    },
    timestampToDatetime(timestamp) {
      return moment.unix(timestamp).format('D/MMM/YYYY HH:mm:ss');
    },
    averageScore() {
      let scoreTotal = 0;
      let weightTotal = 0;
      for (const [sectionIndex, section] of this.template.sections.entries()) {
        for (const [measureIndex, measure] of section.measures.entries()) {
          const score = parseInt(this.submittedValue[sectionIndex].scores[measureIndex]);
          if (typeof score !== 'undefined' && score > 0) {
            const weight = parseFloat(measure.weight);
            scoreTotal += weight * score;
            weightTotal += weight;
          }
        }
      }
      return weightTotal > 0 ? (scoreTotal / weightTotal).toFixed(2) : '';
    },
    commit(command, isDraft) {
      if (!isDraft) {
        if (!this.isSubmittedValueComplete) {
          alert("Some values are missing. Please fill every one of them.");
          return;
        }
        const message = `After you ${command}, you can come back to this page, ` +
          "but cannot edit the data. Do you want to confirm what you entered?"
        if (!confirm(message)) {
          return;
        }
      }
      const reqBody = {
        values: this.submittedValue,
        draft: isDraft
      };
      if (this.isProbation && typeof this.submittedDecision !== 'undefined') {
        reqBody.decision = this.submittedDecision;
      }
      axios.post(`${BACKEND}/forms/${this.$route.params.formId}/${command}`, reqBody, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          if (isDraft) {
            alert("Draft has been saved");
          } else {
            this.returnToListPage();
          }
        }
      });
    },
    submit(isDraft = false) {
      this.commit('submit', isDraft);
    },
    approve(isDraft = false) {
      this.commit('approve', isDraft);
    },
    acknowledge() {
      const isConfirmed = confirm("Do you want to acknowledge this performance evaluation?");
      if (!isConfirmed) {
        return;
      }
      axios.post(`${BACKEND}/forms/${this.$route.params.formId}/acknowledge`, {}, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          this.returnToListPage();
        }
      });
    },
    cancel() {
      this.$router.back();
    },
    returnToListPage() {
      const path = this.isProbation ? '/probation' : '/forms';
      this.$router.push(path);
    }
  },
  beforeCreate() {
    axios.get(`${BACKEND}/forms/${this.$route.params.formId}`, {
      headers: {
        'Authorization': this.$store.getters.idToken
      }
    }).then((response) => {
      if (response.status === 200) {
        this.form = response.data.form;
        this.template = response.data.template;
        this.isEvaluatee = this.$store.getters.email === this.form.evaluatee_email;
        this.isEvaluator = this.$store.getters.email === this.form.evaluator_email;
        this.isApprover = this.$store.getters.email === this.form.approver_email;

        this.submittedValue = new Array(response.data.template.sections.length);
        for (const [i, section] of response.data.template.sections.entries()) {
          this.submittedValue[i] = {
            "scores": new Array(section.measures.length),
            "comment": ""
          };
        }

        if ('approved' in this.form) {
          this.submittedValue = this.form.approved
          this.submittedDecision = this.form.approved_decision;
        } else if ('calibrated' in this.form) {
          this.submittedValue = this.form.calibrated;
        } else if ('submitted' in this.form) {
          this.submittedValue = this.form.submitted;
          this.submittedDecision = this.form.submitted_decision;
        }
      }
    }).catch((error) => {
      console.log(error.response);
      this.$store.commit('logout');
    });
  }
}
</script>