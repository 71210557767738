export function statusColor(status) {
  if (status === 'Pending') {
    return 'text-danger';
  } else if (status === 'Submitted' || status === 'Calibrated') {
    return 'text-primary';
  } else if (status === 'Approved' || status === 'Completed') {
    return 'text-success';
  } else {
    return '';
  }
}
