<template>
  <div v-if="!isProbationList" class="row mt-5">
    <div class="col-1 mt-2">
      <h5>Timeline:</h5>
    </div>
    <div class="col-3">
      <select class="form-select" v-model="timeline">
        <option v-for="timeline in FORM_TIMELINES" :key="timeline" :value="timeline">
          {{ quarterName(company, timeline) }}
        </option>
      </select>
    </div>
  </div>
  <ul class="nav nav-tabs mt-5">
    <li v-if="approvalGroups.length > 0" class="nav-item">
      <a class="nav-link pointer" :class="{ active: isTab('approvalGroups') }" @click="clickTab('approvalGroups')">Approver</a>
    </li>
    <li v-if="approver.length > 0" class="nav-item">
      <a class="nav-link pointer" :class="{ active: isTab('approver') }" @click="clickTab('approver')">Approver</a>
    </li>
    <li v-if="evaluator.length > 0" class="nav-item">
      <a class="nav-link pointer" :class="{ active: isTab('manager') }" @click="clickTab('manager')">Manager</a>
    </li>
    <li v-if="evaluatee.length > 0" class="nav-item">
      <a class="nav-link pointer" :class="{ active: isTab('employee') }" @click="clickTab('employee')">Employee</a>
    </li>
  </ul>
  <div v-if="selectedTab === 'approvalGroups'">
    <h5 class="mt-5">You are the <b>BU Director / Approver</b> of</h5>
    <form-group-card v-for="group in approvalGroups" :key="group.key" :group="group"></form-group-card>
  </div>
  <div v-if="selectedTab === 'approver'">
    <h5 class="mt-5">You are the <b>BU Director / Approver</b> of</h5>
    <name-card v-for="record in approver" :key="record.id" :form="record"></name-card>
  </div>
  <div v-if="selectedTab === 'manager'">
    <h5 class="mt-5">You are the <b>Performance Manager</b> of</h5>
    <name-card v-for="record in evaluator" :key="record.id" :form="record"></name-card>
  </div>
  <div v-if="selectedTab === 'employee'">
    <h5 class="mt-5">You are the evaluated <b>Employee</b> of</h5>
    <name-card v-for="record in evaluatee" :key="record.id" :form="record"></name-card>
  </div>
  <h5 v-if="isEmpty" class="mt-5 text-center"><i>No data</i></h5>
  <div class="mt-5"></div>
</template>

<script>
import axios from 'axios';
import FormGroupCard from './FormGroupCard.vue';
import NameCard from './NameCard.vue';
import { BACKEND } from '@/api';
import { FORM_TIMELINES } from '@/config/quarter';
import { companyName, quarterName } from '@/utils/quarter';

export default {
  name: 'FormList',
  components: { FormGroupCard, NameCard },
  data() {
    return {
      FORM_TIMELINES: Object.freeze(FORM_TIMELINES),
      timeline: '',
      company: '',
      approvalGroups: [],
      approver: [],
      evaluator: [],
      evaluatee: [],
      selectedTab: '',
      isEmpty: false
    };
  },
  computed: {
    isProbationList() {
      return this.$route.name === 'ProbationList'
    }
  },
  watch: {
    $route() {
      this.setTimeline();
    },
    timeline() {
      this.load();
    }
  },
  methods: {
    quarterName,
    isTab(value) {
      return this.selectedTab === value;
    },
    clickTab(value) {
      this.selectedTab = value;
    },
    setTimeline() {
      if (this.isProbationList) this.timeline = 'Probation';
      else this.timeline = FORM_TIMELINES[0];
    },
    formListToApprovalGroups(formList) {
      const groups = {}
      for (const form of formList) {
        let key = `${form.evaluatee_level},${form.evaluatee_bu}`;
        let template_name = '';
        if (!form.template_name.startsWith('VDD-')) {
          key += `,${form.template_name}`;
          template_name = form.template_name;
        }
        if (!(key in groups)) {
          groups[key] = {
            key: key,
            company: form.company,
            timeline: this.timeline,
            level: form.evaluatee_level,
            bu: form.evaluatee_bu,
            template_name: template_name,
            statusList: [],
            count: 0,
          }
        }
        groups[key].statusList.push(form.status);
        groups[key].count += 1;
      }
      return Object.values(groups);
    },
    load() {
      this.approvalGroups = [];
      this.approver = [];
      this.evaluator = [];
      this.evaluatee = [];
      this.selectedTab = '';
      this.isEmpty = false;

      axios.get(`${BACKEND}/forms?timeline=${this.timeline}`, {
        headers: {
          'Authorization': this.$store.getters.idToken
        }
      }).then((response) => {
        if (response.status === 200) {
          if (!this.isProbationList) {
            this.approvalGroups = this.formListToApprovalGroups(response.data.approver);
            this.approvalGroups.sort((a, b) => a.bu?.localeCompare(b.bu));
            this.approvalGroups.sort((a, b) => a.level?.localeCompare(b.level));
          } else {
            response.data.approver.sort((a, b) => a.evaluatee_name?.localeCompare(b.evaluatee_name));
            response.data.approver.sort((a, b) => b.probation_end_date?.localeCompare(a.probation_end_date));
            this.approver = response.data.approver;
          }
          response.data.evaluator.sort((a, b) => a.evaluatee_name?.localeCompare(b.evaluatee_name));
          this.evaluator = response.data.evaluator;
          response.data.evaluatee.sort((a, b) => a.evaluator_name?.localeCompare(b.evaluator_name));
          this.evaluatee = response.data.evaluatee;
          if (this.approvalGroups.length > 0) {
            this.selectedTab = 'approvalGroups';
          } else if (this.approver.length > 0) {
            this.selectedTab = 'approver';
          } else if (this.evaluator.length > 0) {
            this.selectedTab = 'manager';
          } else if (this.evaluatee.length > 0) {
            this.selectedTab = 'employee';
          }
          this.isEmpty = this.selectedTab === '';

          if (!this.company) {
            this.company = companyName(this.evaluator.concat(this.evaluatee));
          }
        }
      }).catch(error => {
        console.log(error.response);
        this.$store.commit('logout');
      });
    }
  },
  created() {
    this.setTimeline();
  }
}
</script>
